@import "@angular/cdk/a11y-prebuilt.css";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

// Fonts
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");

/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */

@tailwind base;

/**
 * This injects Tailwind's component classes and any component classes
 * registered by plugins.
 */

@tailwind components;

/**
 * This injects Tailwind's utility classes and any utility classes registered
 * by plugins.
 */

@tailwind utilities;

@layer base {
  html {
    font-family: "Lato", sans-serif;
  }

  body {
    @apply bg-lm-bg dark:bg-dm-bg text-lm-text dark:text-dm-text #{!important};
  }
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
}

/*
* NOTE: need to use Sass’ interpolation feature to get @apply to work
*/

@layer components {
  .btn-primary {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: rgb(153, 0, 51);
    transition-property: all;
    transition: 0.3s;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 0.5rem;
    border-width: 2px;
    border-color: rgb(153, 0, 51);
    cursor: pointer;
    color: white;
    box-shadow: 0 0px 0px 0px rgb(0, 0, 0, 0);
  }
  .btn-primary:disabled {
    cursor: default;
    opacity: 50%;
  }
  .btn-primary:disabled:hover {
    box-shadow: 0 0px 0px 0px rgb(0, 0, 0, 0);
  }
  .btn-primary:hover {
    box-shadow: 1px 5px 5px 1px rgba(0, 0, 0, .40);
    transition: 0.3s;
  }

  // A white button with old rose text
  .btn-secondary {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: transparent;
    transition-property: all;
    transition: 0.3s;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 0.5rem;
    border-width: 2px;
    border-color: rgb(153, 0, 51);
    cursor: pointer;
    box-shadow: 0 0px 0px 0px rgb(0, 0, 0, 0);
  }
  .btn-secondary:hover {
    box-shadow: 1px 5px 5px 1px rgb(153, 0, 51);
    transition: 0.3s;
  }
}

/*
*
*/

@layer utilities {
  .filter-none {
    filter: none;
  }
  .filter-grayscale {
    filter: grayscale(100%);
  }
}
